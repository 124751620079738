// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-chronoblog-src-pages-404-js": () => import("./../node_modules/gatsby-theme-chronoblog/src/pages/404.js" /* webpackChunkName: "component---node-modules-gatsby-theme-chronoblog-src-pages-404-js" */),
  "component---node-modules-gatsby-theme-chronoblog-src-pages-tags-js": () => import("./../node_modules/gatsby-theme-chronoblog/src/pages/tags.js" /* webpackChunkName: "component---node-modules-gatsby-theme-chronoblog-src-pages-tags-js" */),
  "component---node-modules-gatsby-theme-chronoblog-src-templates-note-js": () => import("./../node_modules/gatsby-theme-chronoblog/src/templates/note.js" /* webpackChunkName: "component---node-modules-gatsby-theme-chronoblog-src-templates-note-js" */),
  "component---node-modules-gatsby-theme-chronoblog-src-templates-post-js": () => import("./../node_modules/gatsby-theme-chronoblog/src/templates/post.js" /* webpackChunkName: "component---node-modules-gatsby-theme-chronoblog-src-templates-post-js" */),
  "component---node-modules-gatsby-theme-chronoblog-src-templates-tag-template-js": () => import("./../node_modules/gatsby-theme-chronoblog/src/templates/tag-template.js" /* webpackChunkName: "component---node-modules-gatsby-theme-chronoblog-src-templates-tag-template-js" */),
  "component---src-pages-cv-mdx": () => import("./../src/pages/cv.mdx" /* webpackChunkName: "component---src-pages-cv-mdx" */),
  "component---src-pages-index-mdx": () => import("./../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-pledge-mdx": () => import("./../src/pages/pledge.mdx" /* webpackChunkName: "component---src-pages-pledge-mdx" */),
  "component---src-pages-projects-mdx": () => import("./../src/pages/projects.mdx" /* webpackChunkName: "component---src-pages-projects-mdx" */),
  "component---src-pages-story-mdx": () => import("./../src/pages/story.mdx" /* webpackChunkName: "component---src-pages-story-mdx" */),
  "component---src-pages-travel-mdx": () => import("./../src/pages/travel.mdx" /* webpackChunkName: "component---src-pages-travel-mdx" */)
}

