module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/Users/macpro/Docs/projects/itzsrv-weblog-prod/node_modules/gatsby-remark-images","id":"98a089e7-b43a-59d2-bb38-64fe6ef8bdf2","name":"gatsby-remark-images","version":"3.3.10","pluginOptions":{"plugins":[],"maxWidth":820,"quality":90,"linkImagesToOriginal":false,"backgroundColor":"transparent"},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]},{"resolve":"/Users/macpro/Docs/projects/itzsrv-weblog-prod/node_modules/gatsby-remark-responsive-iframe","id":"0c95c0d4-3c04-54d4-8e39-232b660f7c94","name":"gatsby-remark-responsive-iframe","version":"2.4.5","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"extensions":[".mdx",".md"],"remarkPlugins":[null,null],"defaultLayouts":{"pages":"/Users/macpro/Docs/projects/itzsrv-weblog-prod/node_modules/gatsby-theme-chronoblog/src/components/page/index.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":820,"quality":90,"linkImagesToOriginal":false,"backgroundColor":"transparent"}},"gatsby-remark-responsive-iframe"]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":820,"quality":90,"linkImagesToOriginal":false,"backgroundColor":"transparent"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Chronoblog Gatsby Theme","short_name":"Chronoblog","start_url":"/","background_color":"#fff","theme_color":"#3a5f7d","display":"standalone","icon":"src/assets/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"ab01e8616ff6b8f06d5e4a08a3f501c4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-XXXXXXXXX-X"},
    }]
